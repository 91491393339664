import { Injectable } from '@angular/core';
import { AbstractRealmRepository } from '../../realm/abstract-realm.service';
import { RealmService } from '../../realm/realm.service';

@Injectable({
  providedIn: "root",
})
export class ImagesService extends AbstractRealmRepository<any>{

    constructor(private realmService: RealmService) {
        super(realmService.getCollection("Images"));
      }

    getImages(data: any) {
        return super.find();
    }

    create(data: any) {
        let dataWithDate = { ...data, "createdAt":  new Date() }
        return super.insertOne(dataWithDate);
    }

    update(id: string, data: any) {
        return super.updateOne({ _id: id }, { $set: data });
    }

    delete(id: string) {
        return super.deleteOne({ _id: id });
    }

    getImagesOne(id: string){
        return super.findOne({ _id: id });
    }
}