<div>
  <div class="d-flex justify-content-between">
    <h2 mat-dialog-title>{{ 'TRANSLATE.GALLERY' | translate }}</h2>
  </div>
  <div class="modal-form-body">
    <div class="gallery">
      <img *ngFor="let image of imageList" [src]="image.url" class="gallery-img" (click)="selectImage(image)">
    </div>
  </div>
  <div class="btn-sec d-flex justify-content-between mt-3">
    <!-- <button type="button" mat-button (click)="onClose()">{{ 'TRANSLATE.CANCEL' | translate}}</button>

    <div class="button-container">
      <div *ngIf="loadSave" class="spinner-container">
        <mat-spinner diameter="24"></mat-spinner>
      </div>
      <button class="msr-3" mat-raised-button color="primary" (click)="onSubmit()" [disabled]="!form.valid || loadSave">{{ 'TRANSLATE.SAVE' | translate}}</button>
    </div> -->
  </div>
</div>