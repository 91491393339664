import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { ImagesService } from 'src/app/services/spartan/masters/images.service';
import { ErrorManagementService } from 'src/app/services/system/error-management.service';

@Component({
  selector: 'app-modal-images',
  templateUrl: './modal-images.component.html',
  styleUrls: ['./modal-images.component.scss']
})
export class ModalImagesComponent implements OnInit {

	imageList: any[] = [];
	loadSave = false

	constructor(
		private imagesService: ImagesService,
		private errorService: ErrorManagementService,
		public dialogRef: MatDialogRef<ModalImagesComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
	) { 
	}

	ngOnInit(): void {
		this.getImages()
	}

	getImages(){
		this.imagesService.getImages({}).subscribe({
			next: (res: any[]) => {
				this.imageList = res
			},
			error: (error) => { 
				this.errorService.getErrorMessage(error)
			}
		})
	}

	onClose(): void {
		this.dialogRef.close();
	}

	selectImage(image) {
		this.loadSave = true
	
		let send = {
		  image: image,
		  imageUrl: image.url,
		}
	
		this.loadSave = false
		this.dialogRef.close(send)
	}
}
